<div>
  <!-- <div *ngIf="
      authState && activeRouteUrl !== '/2factor' ;
      then authenticated;
      else guest
    "></div> -->
  <!-- <div *ngIf="
        authState && activeRouteUrl == '/verify' ;
        then guest;
        
      "></div> -->
</div>

<div>
  @if (authState && activeRouteUrl == '/verify'|| authState && activeRouteUrl.includes( '/usermgmt') || authState && activeRouteUrl.includes('/2factor')  || authState && activeRouteUrl.includes('/signup') ) {
  <div class="contentContainer">
    <div class="topContentContainer">
      <div class="videoContainer">
        <video loop muted autoplay playsInline oncanplay="this.play()" onloadedmetadata="this.muted = true">
          <source src="../assets/videos/video.mp4" type="video/mp4" />
        </video>
      </div>
      <div id="Slogan">
        <h2 class="rotate fade-in">SOTERIA</h2>
        <h3 class="rotate fade-in">Voice Activated Solution </h3>
      </div>
  
      <div class="topRight">
        <router-outlet (activate)="onActivate($event)"></router-outlet>
      </div>
    </div>
    <div class="middleContentContainer"></div>
    <div class="topContentContainer1"></div>
  </div>
  }
  @else if (authState && activeRouteUrl !== '/2factor') {
  <div *ngIf="auth.user | async as user">
    <mat-sidenav-container>
      <mat-sidenav #sidenav [opened]="!checkSmallScreen()" (click)="checkSmallScreen() ? sidenav.toggle() : null"
        [mode]="checkSmallScreen() ? 'over' : 'side'" class="mat-elevation-z10">
        <app-user-profile></app-user-profile>
        <app-device-management [hidden]="activeRouteUrl == '/wifi'"></app-device-management>
      </mat-sidenav>
      <mat-sidenav-content>
        <mat-toolbar>
          <h2 class="headlineStyle">SOTERIA
            <!-- <sup>&copy;</sup> -->
          </h2>
          <button mat-button (click)="sidenav.toggle()" [ngClass]="{
                'mat-button-display': checkSmallScreen(),
                'mat-button-display-none': !checkSmallScreen()
              }">
            <mat-icon>menu</mat-icon>
          </button>
  
        </mat-toolbar>
  
        <router-outlet (activate)="onActivate($event)"></router-outlet>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </div>
  }
   @else {
<div class="contentContainer">
  <div class="topContentContainer">
    <div class="videoContainer">
      <video loop muted autoplay playsInline oncanplay="this.play()" onloadedmetadata="this.muted = true">
        <source src="../assets/videos/video.mp4" type="video/mp4" />
      </video>
    </div>
    <div id="Slogan">
      <h2 class="rotate fade-in">SOTERIA</h2>
      <h3 class="rotate fade-in">Voice Activated Solution </h3>
    </div>

    <div class="topRight">
      <router-outlet (activate)="onActivate($event)"></router-outlet>
    </div>
  </div>
  <div class="middleContentContainer"></div>
  <div class="topContentContainer1"></div>
</div>
  }
</div>
<ng-template #authenticated>

</ng-template>
<ng-template #guest>
  
  <footer>
    <div class="BottomBar">
        <p>{{authState}}</p>
      <p><small>&copy; 2023 mysoteria.ai by Matched Technologies, Inc. All rights reserved.</small></p>
    </div>
  </footer>
</ng-template>