<div class="container">
  <form #forgotPasswordForm="ngForm" ngNativeValidate autocomplete="off">
    <!-- <div class="Form-Link">
      <img src="./../assets/images/CaLogo.png" class="img-fluid" alt="logo" />
    </div> -->

    <div class="form-group">
      <div class="group">
        <label for="email" class="label">Email Address</label>
        <input
          
          placeholder="Email Address"
          type="email"
          class="form-control"
          (ngModel)="(email)"
          name="email"
          required
        />
      </div>
    </div>

    <div>
      <label class="error alert-danger">{{ errorMessage }}</label>
      <label class="  alert-danger ">{{ successMessage }}</label>
      <button
        type="submit"
        (click)="forgotPW(forgotPasswordForm.value)"
        class="inputButtonStyle"
        [disabled]="!forgotPasswordForm.valid"
      >
        Send
      </button>
    </div>
    <div class="hr"></div>

    <div class="linkContainerStyle">
      <a routerLink="/signup">Sign Up</a>
    </div>
  </form>
</div>
