<div class="container">
    <form class="otpContainer">
        <div class="Form-Link">
            <img src="./../assets/images/CaLogo.png" class="img-fluid" alt="logo" />
        </div>
        <!-- <div *ngIf="!successMessage"> -->
        <div class="form-group">
            <div class="group">
                <h2>Congratulations! </h2>
                <p>Your account has been successfully created, please verify your email for ( OTP) one time password.
                </p>

            </div>
        </div>
        <div class="form-group center">
            <button class="inputButtonStyle">
                Resend Email
            </button>
        </div>
    </form>
</div>