<div id="deviceManagementContainer">
  <div>
    <a routerLink="./addnewdevice"> ADD DEVICE </a>
  </div>

  <hr />

  <!-- <div>
    <a href="http://c3vlar360.local:3000/" target="_blank"> CONFIGURE WIFI </a>
  </div>
  <hr /> -->
  <div>
    <a routerLink="./alldevices"> DEVICE LISTING </a>
  </div>
  <hr />
  <div>
    <a routerLink="./alldevicesmap"> DEVICE(S) LOCATION </a>
  </div>
  <hr />
</div>