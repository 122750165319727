
import { TwoFactorAuthenticationService } from "./../../core/two-factor-authentication.service";
import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute, RouterModule } from "@angular/router";
import { AuthService } from "../../core/auth.service";


import { AngularFireDatabase } from '@angular/fire/compat/database';

import { User } from "./../../modelclasses/user";
//import { WindowService } from "src/app/core/window.service";
//import { AppRoutingModule } from "src/app/app-routing.module";
import { FormsModule } from "@angular/forms";
import { WindowService } from "../../core/window.service";
import { CommonModule } from "@angular/common";


@Component({
  selector: "app-user-login",
  templateUrl: "./user-login.component.html",
  styleUrls: ["./user-login.component.scss"],
  providers: [WindowService],
   standalone: true, 
   imports:[RouterModule, FormsModule,CommonModule], 

})
export class UserLoginComponent implements OnInit {
  errorMessage:string="";
  successMessage:string="";
  private socket:any;

  public newuser: User={firstName:"",lastName:"",email:"",phoneForVerification:"", password:"",confirmPassword:"",companyName:""}; // check for this
  constructor(
    public authService: AuthService,
    private database: AngularFireDatabase,
    private router: Router,
    private route: ActivatedRoute,
    private twofactor: TwoFactorAuthenticationService
  ) {
   // debugger;
  }
  /* Two-Step Verification
   Enter the One Time Password (OTP) that has been sent to a phone number ending in 385
   
   Enter OTP: */
  ngOnInit() {
     this.route.queryParams.subscribe(params => {
      console.log("params",params,params['mode'],params['oobCode'])
      debugger;
      /* this.apiKey = params['apiKey'];
      this.mode = params['mode'];
      this.oobCode = params['oobCode']; */
     })
   
    // console.log("Params url state", this.router.getCurrentNavigation());
    // ;
    //console.log("Params url", this.router, this.route);
    /*  console.log("Params url", this.route.snapshot.params.url);
    if (this.route.snapshot.params.url == "/wifi") {
      ;
      this.router.navigate(["/wifi"]);
    } */
    if (this.authService.authenticated()) {
     
   
      /*  if (this.route.snapshot.params.url) {
        this.router.navigate([this.route.snapshot.params.url]);
      } else { */
      this.router.navigate(["/alldevices"]);
      //}
      //this.route.data.subscribe(v => console.log("Params url V", v));
      /*   ;
      this.route.queryParams.subscribe(params => {
        console.log("Params url inside", params);
        if (Object.keys(params).length !== 0) {
          this.router.navigate([params.url]);
        } else {
          this.router.navigate(["/"]);
        }
      }); */
    } else {
    }
    /*  this.socket = socketIo("/", {
      secure: true,
      rejectUnauthorized: false
    }); */
  }

  trySignIn(value:any) {
    // console.log(value);
   
    this.authService.signIn(value).then(
      (res) => {
        this.router.navigate(["/2factor"]);
       // this.router.navigate(["/multifactor"]); 
        this.errorMessage = "";
        this.successMessage = "Your account has been created";
      },
      (err) => {
        console.log(err);
        ;
        

        console.log("login error", err.code);

        //if(err.message=="recaptcha-container")
        if (err.code === "auth/multi-factor-auth-required") {
          // The user is enrolled in MFA, must be verified
          // this.win.windowRef.resolver = err.resolver;
          // this.window.resolver = err.resolve;
          window.resolver = err.resolver;
          console.log(window, err.resolver);
          // this.win.windowRef = { resolver: err.resolver };
        //  this.router.navigate(["/multifactor"]); 
          this.router.navigate(["/2factor"]);
        } else {
          this.errorMessage = err.message;
          this.successMessage = "";
        }
      }
    );
  }

  emitSigninEvent() {
    // that.socket.emit('clientEvent',
    //{ companyName: that.thisDevice.companyInformation.companyName, roomNumber: that.thisDevice.roomNumber });
  }
}
