import { Component, OnInit } from "@angular/core";
import { AuthService } from "../../core/auth.service";
import { Router } from "@angular/router";
import { MatDialog, MatDialogModule } from "@angular/material/dialog";
import { FormsModule } from "@angular/forms";
@Component({
  selector: "app-forgot-password",
  templateUrl: "./forgot-password.component.html",
  styleUrls: ["./forgot-password.component.scss"],
  standalone:true,
  imports:[FormsModule,MatDialogModule],
  providers:[AuthService]
})
export class ForgotPasswordComponent implements OnInit {
  errorMessage="";
  successMessage="";
  email="";

  constructor(
    public authService: AuthService,
    private router: Router,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    
  }
  forgotPW(value:any) {
    console.log("forgot PW");
   
    this.authService
      .forgotPassword(value)
      .then(result => {
        this.errorMessage = "";
        this.successMessage = "Password reset link send to your email";
   
        /* this.dialog
          .open(DialogModalComponent, { width: "500px" })
          .afterClosed()
          .subscribe(() => {
            this.router.navigate(["/login"]);
            this.errorMessage = "";
            this.successMessage = "Password reset link send to your email";
          }); */
      })
      .catch(err => {
        this.errorMessage = err.message;
        this.successMessage = "";
      });
  }
}
