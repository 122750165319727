<div class="table-responsive" *ngIf="listState">
  <div class="loading-section" *ngIf="isLoading">
    <div class="lds-ripple">
      <div></div>
      <div></div>
    </div>
  </div>
  <!--   <div *ngIf="!deviceslistavaliable">No Registered Device Avaliable</div>
  --> <!-- Lamiaa Moustafa 
changing the cell order based the screen size using scss-->
  <div class="form-row table-responsive-lg border border-2 ">

    <table class="table table-bordered table-hover border-custom"
      *ngIf="!isLoading  && (deviceKeysAndLocations | async) as deviceKeysAndLocations">
      <!-- <div *ngFor="let keyAndLocation of deviceKeysAndLocations">
        {{ keyAndLocation | json }} 
        </div> -->
      <thead class="thead-light">
        <tr>
          <th scope="col"></th>
          <th scope="col">No</th>
          <th scope="col">Serial Number</th>
          <th scope="col">Device Location</th>
          <th scope="col">Health</th>
          <th scope="col">Edit</th>
          <th scope="col">Delete</th>
          <th scope="col">Reboot</th>
          <th scope="col">Activate</th>
          <th scope="col">Live Devices</th>
        </tr>
      </thead>



      <tbody *ngFor="let keyAndLocation of deviceKeysAndLocations; let i = index">
        <tr class="accordion-toggle row-hover">

          <td (click)="onToggleCollapse(i, keyAndLocation.sn)">
            <button class="btn-default btn-xs">
              <span [className]="
                  isCollapsed && collapseElement == i
                    ? 'glyphicon   glyphicon glyphicon-minus'
                    : 'glyphicon   glyphicon glyphicon-plus'
                "></span>
            </button>
          </td>
          <td (click)="onToggleCollapse(i, keyAndLocation.sn)">{{ i + 1 }}</td>
          <td (click)="onToggleCollapse(i, keyAndLocation.sn)">{{ keyAndLocation.sn }}</td>
          <td (click)="onToggleCollapse(i, keyAndLocation.sn)">{{keyAndLocation.deviceLocation}}</td>
          <td>
            <a class="btn" (click)="onHealth(keyAndLocation.sn)">
              <i class="fa fa-heartbeat" id="imInfo"></i>
            </a>
          </td>
          <td>
            <a class="btn" (click)="onEdit(keyAndLocation.sn)">
              <i class="glyphicon glyphicon-edit" id="imInfo"></i>
            </a>
            <p *ngIf="keyAndLocation?.isValidKeyPhrase | async" class="error-message">
              Invalid Keyphrase
            </p>
          </td>
          <td>
            <a class="btn" (click)="onDelete(keyAndLocation.sn)">
              <i class="glyphicon glyphicon-trash" id="imInfo"></i>
            </a>
          </td>
          <td>
            <a class="btn" (click)="onReboot(keyAndLocation.sn)">
              <i class="glyphicon glyphicon-off" id="imInfo"></i>
            </a>
          </td>
          <td>
            <div>
              <ui-switch (valueChange)="onActiveValueChange($event, keyAndLocation.sn)" checkedTextColor="white"
                color="#fcb044" switchColor="#fcb044" checkedLabel="Deactivate" uncheckedLabel="Activate"
                size="small"></ui-switch>
            </div>

            <!-- <label class="switch">
              <input type="checkbox" checked />
              <span class="slider round"></span>
              test
            </label> -->
          </td>
          <td>
            <div *ngIf="keyAndLocation?.activate">
              <a class="btn" (click)="openPlayerManual(keyAndLocation.sn)">

                <p>

                  Live Now <br />

                  <i class="fa fa-play" aria-hidden="true" id="imInfo"></i>
                </p>

              </a>
            </div>

            <a class="btn" (click)="openPlayer(keyAndLocation.sn)">
              <div *ngIf="keyAndLocation?.time && keyAndLocation?.activate == undefined">
                <ul *ngFor="let streamObject of keyAndLocation?.time | async">
                  <li *ngIf="(today - streamObject.time) / 1000 / 60 < 2">
                    Live Now <br />

                    <i class="fa fa-play" aria-hidden="true" id="imInfo"></i>
                  </li>
                </ul>
              </div>
            </a>
          </td>
        </tr>

        <tr [className]="
            isCollapsed && collapseElement == i
              ? 'collapse in tableheaderColor  col-12'
              : 'collapse'
          ">
          <td colspan="12" class="no-border">
            <div *ngIf="isCollapsed" class="container">

              <div *ngIf="selectedc3vlar360 | async as mydevice" class="container">
                <div class="row d-flex-column topMargin">
                  <!-- Lamiaa Moustafa-->
                  <!-- deleting extra divs and rows, adjust the layout of the columns in all table row -->
                  <!-- <div class="col-sm-12 col-md-6 deviceInfo">
                  <p class="deviceHeadline">Location :</p>
                  <p class="deviceDetails">{{ mydevice.deviceLocation }}</p>
                </div> -->


                  <div class="col-sm-12 col-md-6 deviceInfo">
                    <p class="deviceHeadline">Activation Key :</p>
                    <p class="deviceDetails">{{ mydevice.keyPhrase }}</p>
                  </div>

                </div>

                <div class="row d-flex-column">

                  <!-- <div class="col-sm-12 col-md-6 deviceInfo">
                  <p class="deviceHeadline">County :</p>
                  <p class="deviceDetails">{{ mydevice.county }}</p>
                </div> -->
                  <div class="col-sm-12 col-md-6 deviceInfo">

                    <p class="deviceHeadline">Deactivation Key :</p>
                    <p class="deviceDetails">{{ mydevice.deactivationKeyPhrase }}</p>
                  </div>
                </div>

                <div class="row d-flex-column">
                  <div class="col-sm-12 col-md-6 deviceInfo">
                    <p class="deviceHeadline ">Address :</p>
                    <p class="deviceDetails"> {{ mydeviceKeysAndLocation[0].address }}</p>
                  </div>

                  <div class="col-sm-12 col-md-6 deviceInfo">
                    <p class="deviceHeadline">Device Location :</p>
                    <p class=" deviceDetails">{{ mydeviceKeysAndLocation[0].deviceLocation }}</p>
                  </div>
                </div>
                <!-- Lamiaa Moustafa-->
              </div>
            </div>
          </td>
        </tr>
      </tbody>
      <tfoot></tfoot>
    </table>
  </div>

  <div class="form-row table-responsive-md">
    <table class="table table-bordered table-hover " *ngIf="!isLoading">
      <thead class="thead-light">
        <tr>
          <th scope="col"></th>
          <th scope="col">No</th>
          <th scope="col">Serial Number dsd</th>
          <th scope="col">Activate</th>
          <th scope="col">Live Devices</th>
          <th scope="col">Health</th>
          <th scope="col">Edit</th>
          <th scope="col">Delete</th>
          <th scope="col">Reboot</th>
        </tr>
      </thead>
      <tbody *ngFor="let key of devicesKeyList; let i = index">
        <tr class="accordion-toggle row-hover">

          <td (click)="onToggleCollapse(i, key.sn)">
            <button class="btn-default btn-xs">
              <span [className]="
                    isCollapsed && collapseElement == i
                      ? 'glyphicon   glyphicon glyphicon-minus'
                      : 'glyphicon   glyphicon glyphicon-plus'
                  "></span>
            </button>
          </td>
          <td (click)="onToggleCollapse(i, key.sn)">{{ i + 1 }}</td>
          <td (click)="onToggleCollapse(i, key.sn)">{{ key.sn }}</td>
          <td>
            <div>
              <ui-switch (valueChange)="onActiveValueChange($event, key.sn)" checkedTextColor="white" color="#fcb044"
                switchColor="#fcb044" checkedLabel="Deactivate" uncheckedLabel="Activate" size="small"></ui-switch>
            </div>
            <!-- <ui-switch 
            [(ngModel)]="true" 
            size="small"        
            color="green"       
            checkedLabel="On"  
            uncheckedLabel="Off" 
            switchColor="#fff"  
            defaultBgColor="#ccc" 
            checkedBgColor="blue"
          ></ui-switch> -->


            <!-- <label class="switch">
                <input type="checkbox" checked />
                <span class="slider round"></span>
                test
              </label> -->
          </td>
          <td>
            <div *ngIf="key?.activate">
              <a class="btn" (click)="openPlayerManual(key.sn)">
                <!--  <div *ngIf="key?.time">
                              <ul *ngFor="let streamObject of key?.time | async"> -->
                <p>
                  <!-- *ngIf="(today - streamObject.time) / 1000 / 60 < 2"> -->
                  Live Now <br />

                  <i class="fa fa-play" aria-hidden="true" id="imInfo"></i>
                </p>
                <!--  </ul>
                            </div> -->
              </a>
            </div>

            <a class="btn" (click)="openPlayer(key.sn)">
              <div *ngIf="key?.time && key?.activate == undefined">
                <ul *ngFor="let streamObject of key?.time | async">
                  <li *ngIf="(today - streamObject.time) / 1000 / 60 < 2">
                    Live Now <br />

                    <i class="fa fa-play" aria-hidden="true" id="imInfo"></i>
                  </li>
                </ul>
              </div>
            </a>
          </td>

          <td>
            <a class="btn" (click)="onHealth(key.sn)">
              <i class="fa fa-heartbeat" id="imInfo"></i>
            </a>
          </td>
          <td>
            <a class="btn" (click)="onEdit(key.sn)">
              <i class="glyphicon glyphicon-edit" id="imInfo"></i>
            </a>
            <p *ngIf="key?.isValidKeyPhrase | async" class="error-message">
              Invalid Keyphrase
            </p>
          </td>
          <td>
            <a class="btn" (click)="onDelete(key.sn)">
              <i class="glyphicon glyphicon-trash" id="imInfo"></i>
            </a>
          </td>
          <td>
            <a class="btn" (click)="onReboot(key.sn)">
              <i class="glyphicon glyphicon-off" id="imInfo"></i>
            </a>
          </td>




        </tr>

        <tr [className]="
              isCollapsed && collapseElement == i
                ? 'collapse in tableheaderColor  col-12'
                : 'collapse'
            ">
          <td colspan="12" class="no-border">
            <!--birhanu  && selectedc3vlar360 | async as mydevice -->
            <div *ngIf="isCollapsed " class="container">
              <div *ngIf="selectedc3vlar360 | async as mydevice" class="container">
                <div class="row d-flex-column topMargin">
                  <!-- Lamiaa Moustafa-->
                  <!-- deleting extra divs and rows, adjust the layout of the columns in all table row -->
                  <div class="col-sm-12 col-md-6 deviceInfo">
                    <p class="deviceHeadline">Location :</p>
                    <p class="deviceDetails">{{ mydevice.deviceLocation }}</p>
                  </div>
                  <div class="col-sm-12 col-md-6 deviceInfo">
                    <p class="deviceHeadline">Activation Key :</p>
                    <p class="deviceDetails">{{ mydevice.keyPhrase }}</p>
                  </div>
                </div>

                <div class="row d-flex-column">
                  <div class="col-sm-12 col-md-6 deviceInfo">
                    <p class="deviceHeadline">County :</p>
                    <p class="deviceDetails">{{ mydevice.county }}</p>
                  </div>
                  <div class="col-sm-12 col-md-6 deviceInfo">
                    <p class="deviceHeadline">Deactivation Key :</p>
                    <p class="deviceDetails">{{ mydevice.deactivationKeyPhrase }}</p>
                  </div>
                </div>

                <div class="row d-flex-column">
                  <div class="col-sm-12 col-md-6 deviceInfo">
                    <p class="deviceHeadline ">Address :</p>
                    <p class="deviceDetails">{{ mydevice.address }} </p>
                  </div>
                  <div class="col-sm-12 col-md-6 deviceInfo">
                    <p class="deviceHeadline">Company Name :</p>
                    <p class=" deviceDetails">{{ mydevice.companyName }}</p>
                  </div>
                </div>
              </div>
              <!-- Lamiaa Moustafa-->
            </div>
          </td>
        </tr>
      </tbody>
      <tfoot></tfoot>
    </table>
  </div>
  <!-- Lamiaa Moustafa 
  changing the cell order based the screen size using scss-->
</div>

<div *ngIf="editState">
  oldEdit
</div>

<div *ngIf="healthState">
  <section class="healthState">
    <div class="form-group healthContainer col-12">
      <button class="inputButtonStyle justify-content-center" (click)="onCancel()">
        <i class="glyphicon glyphicon-remove"></i> cancel
      </button>
    </div>
    <div class="col-12">
      <h4><strong>Serial Number:</strong> {{ serialNumber }}</h4>
      <p class="text-info">
        <strong>IMPORTANT :</strong> If there is no activity reported for over 2
        hours on this device, please Re-Boot the device either manually or find
        the device and press Re-Boot button from
        <a href="http://c3vlar360.local">soteria local</a>
      </p>
    </div>

    <div>
      <app-health [serialNumber]="serialNumber"></app-health>
    </div>
  </section>
</div>

<div *ngIf="wifiState">
  <div class="form-row">
    <app-configc3vlar360 [serialNumber]="serialNumber"></app-configc3vlar360>
  </div>

  <div class="form-row">
    <button class="btn inputButtonStyle center-block" (click)="onCancel()">
      <i class="glyphicon glyphicon-remove"></i> cancel
    </button>
  </div>
</div>