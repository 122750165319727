import {
  Component,
  OnInit
} from "@angular/core";
import { Device } from "./../../modelclasses/device.model";
import { DeviceInfoService } from "../../core/device-info.service";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
//import  firebase from "firebase/compat/app";
import firebase from "firebase/compat/app";
import {
  MatDialog,

} from "@angular/material/dialog";
//import { AddNewDeviceInformationComponent } from "../add-new-device-information/add-new-device-information.component";
import { C3vlarplayerComponent } from "./../c3vlarplayer/c3vlarplayer.component";
import { ImagePdfViewerComponent } from "./../image-pdf-viewer/image-pdf-viewer.component";
import {
  AngularFireUploadTask,
  AngularFireStorageReference,
  AngularFireStorage,
} from "@angular/fire/compat/storage";

import { AngularFireDatabase } from '@angular/fire/compat/database';
import { Observable, of } from "rxjs";
import { map, take, tap } from "rxjs/operators";
import { CommonModule, DatePipe } from "@angular/common";
import { ManageDeviceSocketioService } from "../../shared/managedevicesocketio.service";
import { AuthService } from "../../core/auth.service";

import { Configc3vlar360Component } from "../configc3vlar360/configc3vlar360.component";
import { HealthComponent } from "../health/health.component";
import { UiSwitchModule } from "ngx-ui-switch";
import { LiveStreamService } from "../../core/live-streamc.service";

import { LongitudevalidatorDirective } from "../../shared/longitudevalidator.directive";
import { LatitudeValidatorDirective } from "../../shared/latitude-validator.directive";
import { LocalStorageService } from "../../core/local-storage.service";
import { DatabaselayerService } from "../../core/databaselayer.service";
import { AddCompanyProfileComponent } from "../add-company-profile/add-company-profile.component";
import { Router } from "@angular/router";
//

@Component({
  selector: "app-all-devices",
  templateUrl: "./all-devices.component.html",
  styleUrls: ["./all-devices.component.scss"],
  standalone:true,
  providers:[DatePipe],
  imports:[AddCompanyProfileComponent, FormsModule, ReactiveFormsModule ,CommonModule, Configc3vlar360Component,HealthComponent,UiSwitchModule,LongitudevalidatorDirective,LatitudeValidatorDirective]
})
export class AllDevicesComponent implements OnInit {

  public device: Device;
  public devicesList: Device[]=[];
  devicesKeyListOld: any[] = [];
  deviceKeysAndLocations: Observable<any[]>=of([]);
 ;
  deviceAddressList :any[]=[];
  devicesKeyList: any[] = [];
  //livedDevices: any[] = [];
  public editState: boolean=false;

  public healthState: boolean=false;
  public listState: boolean = true;
  public wifiState: boolean=false;
  localFileURL: string="";
  downloadurl: string="";
  private layoutImage: File | null = null;
  public serialNumber: string="";
  //deviceslistavaliable =false;


   ref: AngularFireStorageReference={} as AngularFireStorageReference;
  // Download URL
  downloadURL: Observable<string>=of("");
  deviceapphealth: string="";
  devicesList1: Observable<any[]>=of([]);
  isLoading = false;
  isCollapsed:boolean = false;
  collapseElement = -1;
  selectedc3vlar360: Observable<any> =of('');;
  mydeviceKeysAndLocation:any;
  today: number = Date.now();
  intervalId:any;
  isActivated = false;
   task: AngularFireUploadTask={} as AngularFireUploadTask;
 
  constructor(
    private deviceInfoService: DeviceInfoService,
    private toastrService: ToastrService,
    private afStorage: AngularFireStorage,
    private datepipe: DatePipe,
    db: AngularFireDatabase,
    private manageDeviceSocket: ManageDeviceSocketioService,
    private authService: AuthService,
    private liveStream: LiveStreamService ,
    public dialog: MatDialog,
    private localStorageService: LocalStorageService,
    private dbLayerService:DatabaselayerService,
    private router: Router
   
    


  ) {
   // ;
    this.device = {
      serialNumber: "",
      deviceInformation: {
        userId: 1,
       

        latitude: 0,
        longitude: 0, // default to dark theme
        keyPhrase: "protect me now",
        deactivationKeyPhrase: "protect me now",
        floorPlanUrl: "",
      
      },
      deviceKeysAndLocations: {
        address: "",
        deviceLocation: "",
      },

    
    };
    /*  this.devicesList1 = db.list('/devices').valueChanges();

    this.devicesList1= this.deviceInfoService.getDevicesList().snapshotChanges().pipe(
      map(changes => 
        changes.map(c => ({ key: c.payload.key, ...c.payload.val() })).
        filter(temp=>temp.deviceInformation && temp.deviceInformation.userId &&
           temp.deviceInformation.userId==firebase.auth().currentUser.uid)
      )
    );
   */
  }

  ngOnInit() {
   

    /*   this.isLoading = true;
  const start = Date.now();
  this.deviceInfoService.getDevicesList().snapshotChanges(). subscribe(item => {
    const millis = Date.now() - start;
     console.log('The item', item, item.length);
   //  localStorage.setItem('devices', JSON.stringify(item));
   //   console.log('from local', JSON.parse(localStorage.getItem('devices')));
     this.initilizeDevicesList(item);
     this.isLoading = false;
   }); */

  // ;

    this.isLoading = true;
    const start = Date.now();
   let userId=firebase.auth().currentUser?.uid;
  
  
    if(userId){
     
      let nodeTOFetch="/users/"+userId+"/deviceKeysAndLocations"
      this.deviceKeysAndLocations= this.deviceInfoService.getList(nodeTOFetch)
      .snapshotChanges().
      pipe(map(changes=>changes.map(c => ({ sn: c.payload.key, ...c.payload.val() })))
      
  );/* .subscribe((result) => {
        console.log("new list result", result);
        ;
      //this.deviceKeysAndLocations=result;
      }); */
      this.isLoading=false;
      console.log("Device keys and locations", this.deviceKeysAndLocations);
      //this.deviceslistavaliable = true;
     /*  this.deviceInfoService
      .getDevicesKeyList(userId)
      .snapshotChanges()
      .subscribe((deviceKeys) => {
        const millis = Date.now() - start;
       // alert(deviceKeys.length);

        if (deviceKeys.length > 0) {
          this.deviceslistavaliable = true;
        }
        this.initilizeDevicesList(deviceKeys);
        this.isLoading = false;
      });  */  
    }
    this.intervalId = setInterval(() => {
      this.today = Date.now();
    }, 1000);
  }

  ngDoCheck() {
    /* let x = 0;
    if (this.devicesKeyList.length != this.devicesKeyListOld.length) {
      this.devicesKeyListOld = this.devicesKeyList;
      console.log("Here I am", this.devicesKeyList);
      if (this.devicesKeyList.length == 0) {
        this.deviceslistavaliable = false;
      }
      this.devicesKeyList.map((result) => {
        this.isLive(result.sn);
        console.log("The result", result, result.sn);

        // this.isLive(result.sn);
      });
    } */
  }

  initilizeDevicesList(deviceKeys:any) {
    console.log("item in initilize", deviceKeys);
    this.devicesKeyList = [];
    this.devicesKeyList = deviceKeys.map((element:any) => {
      const temp = {
        sn: element.key,
        userId: element.payload.toJSON().userId,
      };
      //  const temp = element.payload.toJSON();
      // temp['serialNumber'] = element.key;
    

      if (temp.userId === firebase.auth().currentUser?.uid) {
        // this.isLive(temp.sn);
        return temp;
        /// this.devicesKeyList.push(temp);
      }
      return null;
    });
  }
  /*  initilizeDevicesList(item) {
    this.devicesList = [];
    item.forEach((element) => {
      const temp = element.payload.toJSON();
      temp['serialNumber'] = element.key;
     if ( temp.deviceInformation && temp.deviceInformation.userId && temp.deviceInformation.userId === firebase.auth().currentUser.uid) {
        this.devicesList.push(temp as Device);
    }
    });
  } */
  onToggleCollapse(elementId:any, sn: string) {
    console.log("Element id", elementId, this.collapseElement,"sn",sn);
    //  this.editState = true;

    this.serialNumber = sn;

    if (this.collapseElement == -1) {
      this.isCollapsed = !this.isCollapsed;

      this.selectedc3vlar360 = this.deviceInfoService
        .getDeviceInformationField(this.serialNumber)
        .valueChanges();
      this.deviceKeysAndLocations.pipe(map(devices => {
       
          /* this.mydeviceKeysAndLocation= obj.filter((obj) => {
               return obj.sn === this.serialNumber
               });  */
               
             return devices.filter(devices => devices.sn === this.serialNumber);
           })).subscribe((result) => {
            console.log("new list result", result);
            this.mydeviceKeysAndLocation=  result;
          
           }
            );
    } else if (this.collapseElement !== elementId) {
      this.isCollapsed = !this.isCollapsed;
      this.isCollapsed = !this.isCollapsed;
      this.selectedc3vlar360 = this.deviceInfoService
        .getDeviceInformationField(this.serialNumber)
        .valueChanges();
          this.deviceKeysAndLocations.pipe(map(devices => {
       
          /* this.mydeviceKeysAndLocation= obj.filter((obj) => {
               return obj.sn === this.serialNumber
               });  */
               
             return devices.filter(devices => devices.sn === this.serialNumber);
           })).subscribe((result) => {
            console.log("new list result", result);
            this.mydeviceKeysAndLocation= result
            ;
           }
            );
      //  this.mydeviceKeysAndLocation=this.deviceKeysAndLocations.find(obj => obj.sn === sn);
          
          
    } else {
      this.isCollapsed = !this.isCollapsed;
    }
    this.collapseElement = elementId;
  }
 
  onCancel() {
    this.listState = true;
    this.healthState = false;
    this.wifiState = false;
    this.editState = false;
    this.localFileURL = "";
    //   console.log(this.device)
    console.log(this.datepipe.transform(Date.now(), "short"));
  }

  onDelete(sn: string) {
    if (confirm("Are you sure you want to delete this device?") === true) {
      console.log("Test 1 deviceKey List",sn, this.devicesKeyList);
      let userId=firebase.auth().currentUser?.uid;
  
  
    if(userId){
      let nodeTODeleteFromUser="/users/"+userId+"/deviceKeysAndLocations/"+sn;
      let nodeTODeleteFromDevice="/devices/"+sn;
      this.dbLayerService.deleteUserDataByNode(nodeTODeleteFromUser);
      this.dbLayerService.deleteDeviceDataByNode(nodeTODeleteFromDevice);
     
    //  this.deviceInfoService.deleteDevice(sn);
      console.log("Test 1 deviceKey List", this.devicesKeyList);
      this.toastrService.warning("Deleted Successfully", "Device register");
    }
  }
  }
  onHealth(sn: string) {
    this.healthState = true;
    this.listState = false;
    this.editState = false;
    this.serialNumber = sn;
    this.router.navigate(["/devicehealth",sn],{
      queryParams: { mode: 'edit', source: 'all-devices',sn:sn }
    });
   // this.router.navigate(["/health",sn],{
    // this.device = Object.assign({}, device);
  }
  onWifi(sn: any): void {
    this.wifiState = true;
    this.healthState = false;
    this.listState = false;
    this.editState = false;
    this.serialNumber = sn;
    //this.device=Object.assign({},device);
    // console.log('sn',this.device);
  }
  onEdit(sn: string) {
    this.router.navigate(["/editdevice",sn],{
      queryParams: { mode: 'edit', source: 'all-devices',sn:sn }
    });
  }
  onKey(event: any) {
    // without type info
    /*  this.device.deviceInformation.deviceCode =
      this.device.deviceInformation.companyName +
      this.device.deviceInformation.roomNumber; */
  }
  async onActiveValueChange(event:any, sn: string) {
    console.log("Event", event, sn);
    ;
    this.isActivated = event;
    console.log("Event", event, sn, this.isActivated);
    this.manageDeviceSocket.activateDevice({
      sn: sn,
      status: this.isActivated ? "activate" : "deactivate",
    });
    //let index = this.devicesKeyList.findIndex((s) => s.sn == sn);
//;
   // this.devicesKeyList[index].activate = event;
   /* this.live  this.deviceKeysAndLocations.pipe(
      map(arr => arr.map(obj =>{ 
        console.log("Object",obj);
        if(obj.sn === sn) {
          return {
            ...obj,
            activate: event
          }
        }
        return obj;
        }))
    );  */ 

    this.deviceKeysAndLocations.pipe(
      take(1),
      tap(arr => {
        arr.forEach(obj => {
          if(obj.sn === sn) {
            obj.activate = event;
          }
          //obj.newProp = `NewValue-${obj.sn}`;  // Modify each object in place
        });
      })
    ).subscribe(updatedArr => {
      this.deviceKeysAndLocations = of(updatedArr);
    }
    );
  }
  onReboot(sn: string) {
    this.manageDeviceSocket.rebootDevice({ sn: sn, status: "reboot" });
  }
  onActivate(sn: string) {
    console.log("Activate", sn);
    this.isActivated = !this.isActivated;
  }
  openPlayer(sn:any) {
    console.log("SN", sn);
    this.dialog
      .open(C3vlarplayerComponent, {
        data: { serialNumber: sn },
      })
      .afterClosed()
      .subscribe(() => {});
  }
  openPlayerManual(sn:any) {
    this.liveStream
      .getEndPoint(sn)
      .then((data:any ) => {
        console.log("Live end point", data);
        return this.liveStream.getStreamingSessionUrl(data, sn);
      })
      .then((response:any) => {
        console.log("Live session url", response);
        this.dialog
          .open(C3vlarplayerComponent, {
            data: { url: response, serialNumber: sn },
          })
          .afterClosed()
          .subscribe(() => {});
      })
      .catch((error:any) => {
        console.log("Live stream session url error", error.message);
      });
  }
  openImagePdf(url:any, sn:any) {
    this.dialog
      .open(ImagePdfViewerComponent, {
        data: { url: url, serialNumber: sn },
      })
      .afterClosed()
      .subscribe(() => {});
  }

  isLive(sn:any) {
    let m = this.devicesKeyList.findIndex((s) => s.sn == sn);

    this.devicesKeyList[m].time = this.deviceInfoService
      .isDeviceStreaming(sn)
      .valueChanges();
    this.devicesKeyList[m].isValidKeyPhrase = this.deviceInfoService
      .isValidKeyPhrase(sn)
      .valueChanges();
    console.log("Device Key list", this.devicesKeyList);
    /* this.liveStream
      .getEndPoint(sn)
      .then((data) => {
        console.log("Live end point", data);
        return this.liveStream.getStreamingSessionUrl(data, sn);
      })
      .then((response) => console.log("Live session url", response))
      .catch((error) => {
        console.log("Live stream session url error", error.message);
      }); */
  }
 
  
  
  

  
}
