import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../core/auth.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-new-password',
  standalone: true,
  
  imports: [FormsModule],
  templateUrl: './new-password.component.html',
  styleUrl: './new-password.component.scss'
})
export class NewPasswordComponent implements OnInit {
  successMessage:string=""
   errorMessage:string=""
   newPassword:string=""
   oobCode:string =""
    constructor(
    private route: ActivatedRoute,
    private authService:AuthService,
 private router: Router,   
    private toastr: ToastrService
  ) {}
 ngOnInit() {

  this.route.queryParams.subscribe(params => {
      this.oobCode= params['oobCode'];
      
      
    });
  // alert("New pass")
  }       
  async sendNewPassword(value:any){
      try {
        const {newPassword}=value
    if(newPassword){
       console.log("Code",this.oobCode,newPassword)
     const result=   await  this.authService.resetPassword(this.oobCode,newPassword)
     
         this.toastr.success('Password has been reset successfully.');
            this.router.navigate(['/login']);
     
       
    }
  
      
    } catch (error:any  ) {
      this.toastr.error('Error resetting password: ' + error.message);
        
      console.log("Error New Password",error)
      
    }
  

  }

}
