import { Component } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../core/auth.service';

@Component({
  selector: 'app-user-management',
  standalone: true,
  imports: [],
  templateUrl: './user-management.component.html',
  styleUrl: './user-management.component.scss'
})
export class UserManagementComponent {
  mode: string | null = '';
  oobCode: string | null = '';
  apiKey: string | null = '';
  constructor(public router: Router, private route: ActivatedRoute,private afAuth: AngularFireAuth, public authService: AuthService,) { }
  
  ngOnInit() {
    
    this.route.queryParams.subscribe(params=>{
      this.mode = params['mode'];
      this.oobCode = params['oobCode'];
      this.apiKey = params['apiKey'];
      this.afAuth.currentUser.then(user => {
        if (user && user.emailVerified) {
          user.reload();
       
          this.router.navigate(["/2factor",{ replaceUrl: true }]);
          
        } 
        else  if (this.mode === 'verifyEmail' && this.oobCode) {
       this.authService.verifyEmail(this.oobCode);
      }
      else if(this.mode==="resetPassword" && this.oobCode){
          this.router.navigate(["/newpassword"], { queryParams: { oobCode: this.oobCode } });
       } else {
          console.log("User",user)
        
        
         // http://localhost:4200/usermgmt?mode=resetPassword&oobCode=xPr3kyAvZvUJCfq1QQrKs0V7wiDsPbhqfosq0hVLrR4AAAGUZWOhHA&apiKey=AIzaSyBqOYdiWgUDOzD_bL3ZCfRcjHmpLKlxfzk&lang=en
 this.router.navigate(["/login"]);
        }
      });
     
      
    })
  }
  



}
