<div class="container">
  <form #signinForm="ngForm" class="LoginStyle">
    <div class="Form-Link">
      <img src="./../assets/images/CaLogo.png" class="img-fluid" alt="logo" />
      <!-- <ul>
        <li>
          <a [routerLink]="['/login']" routerLinkActive="active">Sign In</a>
        </li> -->
      <!-- <li>
          <a [routerLink]="['/signup']" class="NotActiveStyle">Sign Up</a>
        </li> -->
      <!--  <li>
          <a [routerLink]="['/emailVerification']" class="NotActiveStyle">Sign Up</a>
        </li> -->
      <!-- </ul> -->
    </div>

    <div class="form-group">
      <div class="group">
        <label for="email" class="label">User Name</label>
        <input type="email" class="form-control inputTextStyle" (ngModel)="(newuser.email)" name="email"
          placeholder="User Name" required />
      </div>
    </div>

    <div class="form-group">
      <div class="group">
        <label for="password" class="label">Password</label>
        <input type="password" class="form-control inputTextStyle" (ngModel)="(newuser.password)" name="password"
          placeholder="Password" required />
        <div class="errorMessage">
          <label class="error alert-danger">{{ errorMessage }}</label>
          <label class="success alert-success">{{ successMessage }}</label>
        </div>
      </div>
      <button type="submit" (click)="trySignIn(signinForm.value)" class="inputButtonStyle">
        SIGN IN
      </button>
      <div class="hr"></div>
      <div class="linkContainerStyle">
        <a routerLink="/forgotpassword" routerLinkActive="active">Forgot Password?
        </a>
        <!-- <a routerLink="/signup" routerLinkActive="active" class="linkStyle"
          >Sign Up</a
        > -->
      </div>
    </div>
  </form>
</div>