/* import { Component } from '@angular/core';

import { AuthService } from './core/auth.service';
import {DeviceInfoService} from './core/device-info.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})


export class AppComponent {
  title = 'Covert Alert Enterprise Safty app';
  serialnumber:Array<any>;
  lat:number=18.5793;
  lng:number= 73.8143;
  constructor(private deviceInfoService: DeviceInfoService,public auth:AuthService) {
    this.deviceInfoService.getSerialNumber().subscribe(res=>this.serialnumber=res);

  }
  ngOnInit() {
}
}
 */

import { Component } from "@angular/core";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";

import { ActivatedRoute, Router, RouterModule } from "@angular/router";

 import { AuthService } from "./core/auth.service";

//import { DeviceInfoService } from "./core/device-info.service";
//Birhanu import * as socketIo from "socket.io-client";
//import { PhoneTwoFactorAuthenticationComponent } from "./ui/phone-two-factor-authentication/phone-two-factor-authentication.component";
import { CommonModule, Location } from "@angular/common";
import { MatIconModule } from "@angular/material/icon";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatToolbarModule } from "@angular/material/toolbar";
import { DeviceManagementComponent } from "./ui/device-management/device-management.component";
import { UserProfileComponent } from "./ui/user-profile/user-profile.component";

//import { TwofactorService } from "./core/twofactor.service";
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
    standalone:true,
  imports:[CommonModule,RouterModule, MatIconModule,MatSidenavModule,MatToolbarModule  ,DeviceManagementComponent,UserProfileComponent ]   
})

export class AppComponent {
  
  title = "Covert Alert Enterprise Safty app";
  //serialnumber: Array<any>;
  isSmallScreen: boolean=false;
  private socket: any;
  activeRouteUrl: string="";
  authState: boolean = true;
  userVerified:boolean=false;
  lat = 18.5793;
  lng = 73.8143;

  constructor(
    // private deviceInfoService: DeviceInfoService,
   public auth: AuthService,
    //private  service :TwofactorService,
    private breakpointObserver: BreakpointObserver,
  /* birhanu  private manageDeviceSocketIo: ManageDeviceSocketioService, */
    private router: Router,
    private route:ActivatedRoute,
    private _location: Location
  ) {
    
   
    /* this.deviceInfoService.getSerialNumber().subscribe(res => {
      this.serialnumber = res;
    }); */
    // this.isSmallScretrue
    // console.log('is small screen',this.isSmallScreen);
    breakpointObserver
      .observe([Breakpoints.HandsetLandscape, Breakpoints.HandsetPortrait])
      .subscribe((result) => {
        this.isSmallScreen = result.matches;
      });
  console.log("isAuthenticated app", this.auth.authenticated());
    this.auth.user.subscribe((user) => {
      if (user) {
        this.authState = true;
        this.userVerified=user.emailVerified;
        console.log("isAuthenticated app 1",user,this.authState)
      } else {
        this.authState = false;
         this.userVerified=false
         console.log("isAuthenticated app 2",user,this.authState)
      }
    });
     /* 
 birhanu
    this.socket = socketIo("/", {
      secure: true,
      rejectUnauthorized: false,
    });
    //  ;
    this.socket.on("connection", function () {
      console.log("Socket in angular");
      this.socket.on("broadcast", function (data) {
        console.log("The datat", data);
      });
    }); */
    /* this.socket = socketIo("https://socketio.c3vlar360.com/", {
      secure: true,
      rejectUnauthorized: false,
    }); */
  }

  ngOnInit() {
   /*  this.router.events.subscribe(event => {
console.log("Router Event",event);
}); */

  

    // this.wifiSocketIo.clientReciver();
  }
  onActivate($event: any) {
   
     console.log("ON ACTIVATE 1", this.route.url);
    this.activeRouteUrl = this.router.url;
    console.log("ON ACTIVATE", this.activeRouteUrl);
  }
  checkSmallScreen(): boolean {
    return this.isSmallScreen;
  }
}
