<div class="container">
    <form #accountActivatioForm="ngForm" class="LoginStyle">
        <!-- <form #VerifyForm="ngForm" class="LoginStyle"> -->
        <div class="Form-Link">
            <img src="./../assets/images/CaLogo.png" class="img-fluid" alt="logo" />
        </div>

        <div class="form-group">
            <div class="group">
                <label for="email" class="label">Activation Code{{accountActivationCode}}</label>
                <input type="text" class="form-control inputTextStyle" placeholder="Activation Code"
                    (ngModel)="accountActivationCode" name="accountActivationCode" required />
                <!-- <input type="email" class="form-control inputTextStyle" (ngModel)="(newuser.email)" name="email"
                        placeholder="Email Address" required /> -->
            </div>
        </div>

        <div class="form-group">
            <label class="error alert-danger">{{ errorMessage }}</label>
            <label class="success alert-success">{{ successMessage }}</label>
        </div>

        <div class="form-group accountDiv">
            <button type="submit" class="inputButtonStyle"
                (click)="verifyAccountActivationCode(accountActivatioForm.value)">
                Verify Activation Code
            </button>
            <div class="form-group">
                <label class="error alert-danger">{{ errorMessage }}</label>
                <label class="success alert-success">{{ successMessage }}</label>

            </div>
            <!-- <hr class="line"> -->
            <div class="form-group center">
                <div class="hr"></div>
            </div>
            <ul class="haveAccount">
                <li>
                    <a [routerLink]="['/login']" class="NotActiveStyle" routerLinkActive="active">Already have an
                        account?</a>
                </li>
            </ul>
        </div>
    </form>
</div>