import { Component } from '@angular/core';
import { AuthService } from '../../core/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-verify-email-for-otp',
  standalone: true,
  imports: [],
  templateUrl: './verify-email-for-otp.component.html',
  styleUrl: './verify-email-for-otp.component.scss'
})
export class VerifyEmailForOTPComponent {
   constructor(
    public authService: AuthService,
 
    private router: Router,
   
  ) {
   // debugger;
  }
  ngOnInit() {
    console.log("verify email for otp",this.authService.authenticated());
   
   
  if (this.authService.authenticated()) {
     
   

      this.router.navigate(["/alldevices"]);
    } 
  }
}
