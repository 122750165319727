<div class="container">
  <!-- #signinForm="ngForm" -->
  <form #VerifiyForm="ngForm">
    <div class="Form-Link">
      <img src="./../assets/images/CaLogo.png" class="img-fluid" alt="logo" />
    </div>
    <div>
      <label class="error alert-danger">{{ errorMessage }}</label>
      <div id="recaptcha-container" [class.hidden]="isVerificationSend"></div>

    </div>

    <div *ngIf="!isVerificationSend">
      <div class="form-group">
        <div class="group">
          <label class="numStyle" for="username" class="label">Enter Phone Number</label>
          <input type="text" (keypress)="keyPressPhone($event)" class="form-control" [(ngModel)]="phoneForVerification"
            name="phoneForVerification" minlength="10" maxlength="10" placeholder="Enter phone number"
            [readonly]="phoneInputReadOnly" />
        </div>

      </div>

      <div>
        <button type="submit" (click)="sendLoginCode(VerifiyForm.value)" class="inputButtonStyle" aria-placeholder="">
          Send Code
        </button>
        <!-- <button  (click)="sendLoginCode(VerifiyForm.value)" class="inputButtonStyle" aria-placeholder="">
          Send Code
        </button> -->
        <div class="groupDiv center">
          <div class="hr"></div>
          <button class="LogoutButtonStyle" (click)="logout()">Logout</button>
        </div>

      </div>
      <div class="form-group">
        <div class="loading-section" *ngIf="isLoading">
          <div class="lds-ripple">
            <!-- <div></div>
            <div></div> -->
          </div>
        </div>
      </div>
    </div>


    <div *ngIf="isVerificationSend">
      <p>Enter Verification code :</p>

      <div class="form-group">
        <input type="text" class="form-control" [(ngModel)]="verificationCode" name="verificationCode" minlength="10"
          maxlength="10" />
      </div>
      <div class="form-group">
        <button type="submit" (click)="verifyLoginCode()" class="inputButtonStyle">
          Verify
        </button>
      </div>
      <div class="form-group">
        <button class="inputButtonStyle" (click)="logout()">
          <span class="glyphicon glyphicon-log-out"></span> Log out
        </button>
      </div>
    </div>

  </form>

</div>