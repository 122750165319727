<div class="container">
    <form #newPasswordForm="ngForm" ngNativeValidate autocomplete="off">
      

        <div class="form-group">
            <div class="group">
                <label for="newPassword" class="label">New Password</label>
                <input placeholder="New Password" type="password" class="form-control" (ngModel)="(newPassword)" name="newPassword"
                    required />
            </div> 
        </div>

        <div>
            <label class="error alert-danger">{{ errorMessage }}</label>
            <label class="  alert-danger ">{{ successMessage }}</label>
           <button type="submit" (click)="sendNewPassword(newPasswordForm.value)" class="inputButtonStyle"
                [disabled]="!newPasswordForm.valid">
                Send
            </button> 
        </div>
        <div class="hr"></div>

        <div class="linkContainerStyle">
            <a routerLink="/signup">Sign Up</a>
        </div>
    </form>
</div>